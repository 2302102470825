<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li
              v-if="getUrlParameter('tesouraria')"
              class="fd-app-breadcrumb-item active"
            >
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>
            </li>
            <li
              v-else
              class="fd-app-breadcrumb-item"
            >
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Documentos</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Documentos da
                  <span v-if="getUrlParameter('tesouraria')">tesouraria</span>
                  <span v-else>secretaria</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de documentos
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="showModal('modalCriarTipoDocumento')"
                          >
                            <small>+ Criar documento</small>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th>
                                  <small
                                    class="font-weight-bold"
                                  >Tipo documento</small>
                                </th>

                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSecretariaTipoDocumentosLoading">
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                  v-html="fastLoadingDados()"
                                />
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastSecretariaTipoDocumentos.length">
                              <tr
                                v-for="documento in fastSecretariaTipoDocumentos"
                                :key="documento.tipo_documento"
                              >
                                <td class="align-middle text-center">
                                  {{ documento.nome_documento }}
                                </td>                                
                                <td class="text-center align-middle">                                  
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarTipoDocumento(documento)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                    @click="exibeModalExcluirTipoDocumento(documento)"
                                  >
                                    <small>Excluir</small>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="2"
                                  class="text-center"
                                >
                                  Nenhum documento cadastrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  v-if="getUrlParameter('tesouraria')"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/tesouraria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
                <a
                  v-else
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarTipoDocumento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo documento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTipoDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome do documento</label>
              <input
                v-model="fastSecretariaTipoDocumentoNovo.nome_documento"
                type="text"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarSecretariaTipoDocumento()"
                >
                  Criar documento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarTipoDocumento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar documento</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarTipoDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Nome do documento</label>
              <input
                v-model="fastSecretariaTipoDocumentoEditar.nome_documento"
                type="text"
                class="form-control"
                maxlength="30"
              >
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarTipoDocumento()"
                >
                  Confirmar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirTipoDocumento"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir documento?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirTipoDocumento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluirTipoDocumento()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoSecretariaDocumentos",
  components: {},

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Documento
      fastSecretariaTipoDocumentos: [],
      fastSecretariaTipoDocumentosLoading: true,
      fastSecretariaTipoDocumentoNovo: {
        tipo_documento: 0,
        nome_documento: "",
        id_plataforma: 0
      },
      fastSecretariaTipoDocumentoEditar: {
        tipo_documento: 0,
        nome_documento: "",
        id_plataforma: 0
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Tipo documentos
            this.getSecretariaTipoDocumentos(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    getSecretariaTipoDocumentos(id_plataforma){
      this.fastSecretariaTipoDocumentosLoading = true
      this.promiseGetFastApi("api/fast_secretaria_tipo_documento/lista", "id_plataforma=" + id_plataforma).then(obj => {
        console.log("getSecretariaTipoDocumentos", obj);
        if (obj.length) this.fastSecretariaTipoDocumentos = obj;
        this.fastSecretariaTipoDocumentosLoading = false
      }).catch(e => {
        console.log(e);
        this.fastSecretariaTipoDocumentosLoading = false
      })
    },
    async criarSecretariaTipoDocumento() {
      const fast_secretaria_tipo_documento = {
        nome_documento: this.fastSecretariaTipoDocumentoNovo.nome_documento,
        id_plataforma: this.$route.params.id_plataforma
      };
      console.log("fast_secretaria_tipo_documento", fast_secretaria_tipo_documento)
      this.promiseInserirFastApi(fast_secretaria_tipo_documento, "fast_secretaria_tipo_documento").then((res) => {
        console.log("criarSecretariaTipoDocumento", res)
        this.exibeToasty("Documento criado com sucesso", "success");
        this.hideModal("modalCriarTipoDocumento");
        this.showModal("modalCriarRequisito");
        // Tipo documentos
        this.fastSecretariaTipoDocumentos = res;
      }).catch((e) => {
        this.exibeToasty("Erro ao criar requisito", "error");
      });
    },
    exibeModalEditarTipoDocumento(documento) {
      this.fastSecretariaTipoDocumentoEditar = documento;
      this.showModal("modalEditarTipoDocumento");
    },
    async editarTipoDocumento() {
       this.$store.state.fastCarregando = true;
        this.promiseAtualizarFastApi(this.fastSecretariaTipoDocumentoEditar, "fast_secretaria_tipo_documento").then(e => {
          this.exibeToasty("Salvo com sucesso", "success");
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarTipoDocumento");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    exibeModalExcluirTipoDocumento(documento) {
      this.fastSecretariaTipoDocumentoEditar = documento;
      this.showModal("modalExcluirTipoDocumento");
    },
    async excluirTipoDocumento(){
      this.promiseExcluirFastApi(this.fastSecretariaTipoDocumentoEditar, "fast_secretaria_tipo_documento").then(e => {
        this.exibeToasty("Documento excluído com sucesso", "success");        
        this.getSecretariaTipoDocumentos(this.$route.params.id_plataforma);   
        this.hideModal("modalExcluirTipoDocumento");
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.$store.state.fastCarregando = false;
      });
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
